<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <AppHeader />
    <v-divider></v-divider>
    <v-app-bar
      v-if="$vuetify.breakpoint.name !== 'xs'"
      class="hidden-sm-and-down headGrad"
      :color="transAppBar && $route.path == '/' ? '#005f32' : '#005f32'"
      :height="'75px'"
      elevation="6"
      app
      dark
      elevate-on-scroll
      v-scroll="onScroll"
    >
      <v-layout wrap justify-center>
        <v-flex align-self-center xs12 px-md-2>
          <v-layout wrap justify-start>
            <v-flex align-self-center sm12 md12 lg12>
              <v-layout wrap justify-center>
                <v-flex xs2 sm3 md2 lg2 xl1 py-0 align-self-start text-left>
                  <router-link class="routerlink" :to="(name = '/')">
                    <v-img
                      contain
                      height="60px"
                      width="100%"
                      :src="require('./../assets/images/wtilogo.png')"
                    ></v-img>
                  </router-link>
                </v-flex>

                <v-flex
                  xs2
                  sm1
                  md10
                  lg10
                  px-6
                  align-self-center
                  text-right
                  v-if="$route.name == 'home'"
                >
                  <div>
                   
                    <v-btn
                      color="#f07305"
                      large
                      outlined
                      text
                      :to="'/Winners'"
                      class="rounded-xl text-none"
                      @click="interact()"
                    >
                      <span
                        class="hovbtn"
                        style="
                  font-family: poppinssemibold;
                  font-size: 16px;
                  cursor: pointer; 
                "
                      >
                        View Winners <v-icon>mdi-arrow-right</v-icon>
                      </span>
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  sm1
                  md10
                  lg10
                  px-6
                  align-self-center
                  text-right
                  v-else
                >
                  <div>
                    
                    <v-btn
                      color="#f07305"
                      large
                      outlined
                      text
                      :to="'/'"
                      class="rounded-xl text-none"
                      @click="interact()"
                    >
                      <span
                        class="hovbtn"
                        style="
                  font-family: poppinssemibold;
                  font-size: 16px;
                  cursor: pointer;
                  
                "
                      >
                        Go Home <v-icon>mdi-arrow-left</v-icon>
                      </span>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex align-self-center xs8 sm6 md6 lg6 text-right>
              <v-layout wrap justify-end>
                <v-flex sm12 md12 lg12 align-self-center text-right>
                  <v-layout wrap justify-end>
                    <v-flex
                      v-for="(item, i) in menus"
                      :key="i"
                      sm4
                      md2
                      lg2
                      xl2
                      align-self-center
                      text-center
                    >
                      <!-- <span
                        v-if="item.name == 'Research'"
                        @click="
                          item.name == 'Research'
                            ? (menu = true)
                            : (menu = false)
                        "
                        :class="transAppBar ? 'hid' : 'hov'"
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-layout
                              justify-center
                              color="#86ab5ef3"
                              v-bind="attrs"
                              v-on="on"
                              :class="transAppBar ? 'hid' : 'hov'"
                            >
                              <span
                                style="cursor: pointer"
                                :class="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'MDapplist'
                                    : 'LGapplist'
                                "
                              >
                                {{ item.name }}
                              </span>
                            </v-layout>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in dropdown"
                              :key="index"
                            >
                              <v-list-item-title>
                                <v-card
                                  width="200px"
                                  :class="transAppBar ? 'hid' : 'hov'"
                                >
                                  <span
                                    :class="
                                      $vuetify.breakpoint.name == 'md'
                                        ? 'MDapplist'
                                        : 'LGapplist'
                                    "
                                    >{{ item.title }}</span
                                  >
                                </v-card>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span> -->
                      <!-- <span
                        v-else-if="item.name == 'Get Involved'"
                        @click="
                          item.name == 'Get Involved'
                            ? (menu = true)
                            : (menu = false)
                        "
                        :class="transAppBar ? 'hid' : 'hov'"
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-layout
                              justify-center
                              color="#86ab5ef3"
                              v-bind="attrs"
                              v-on="on"
                              :class="transAppBar ? 'hid' : 'hov'"
                            >
                              <span
                                style="cursor: pointer"
                                :class="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'MDapplist'
                                    : 'LGapplist'
                                "
                              >
                                {{ item.name }}
                              </span>
                            </v-layout>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in drop"
                              :key="index"
                            >
                              <v-list-item-title>
                                <v-card
                                  width="200px"
                                  :class="transAppBar ? 'hid' : 'hov'"
                                >
                                  <router-link :to="item.route">
                                    <span
                                      :class="
                                        $vuetify.breakpoint.name == 'md'
                                          ? 'MDapplist'
                                          : 'LGapplist'
                                      "
                                      >{{ item.title }}</span
                                    >
                                  </router-link>
                                </v-card>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span> -->
                      <!-- <span
                        v-else-if="item.name == 'Activities'"
                        @click="
                          item.name == 'Activities'
                            ? (menu = true)
                            : (menu = false)
                        "
                        :class="transAppBar ? 'hid' : 'hov'"
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-layout
                              justify-center
                              color="#86ab5ef3"
                              v-bind="attrs"
                              v-on="on"
                              :class="transAppBar ? 'hid' : 'hov'"
                            >
                              <span
                                style="cursor: pointer"
                                :class="
                                  $vuetify.breakpoint.name == 'md'
                                    ? 'MDapplist'
                                    : 'LGapplist'
                                "
                              >
                                {{ item.name }}
                              </span>
                            </v-layout>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in activities"
                              :key="index"
                            >
                              <v-list-item-title>
                                <v-card
                                  width="200px"
                                  :class="transAppBar ? 'hid' : 'hov'"
                                >
                                  <router-link :to="item.route">
                                    <span
                                      :class="
                                        $vuetify.breakpoint.name == 'md'
                                          ? 'MDapplist'
                                          : 'LGapplist'
                                      "
                                      >{{ item.title }}</span
                                    >
                                  </router-link>
                                </v-card>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </span> -->

                      <a
                        :class="transAppBar ? 'hid' : 'hov'"
                        :href="item.route"
                      >
                        <span
                          :class="
                            $vuetify.breakpoint.name == 'md'
                              ? 'MDapplist'
                              : 'LGapplist'
                          "
                        >
                          {{ item.name }}
                        </span>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-menu v-model="menu1" offset-y>
      <v-list>
        <v-list-item v-for="(item, i) in subs" :key="i" link>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import AppHeader from "./../layout/AppHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      appLoading: false,
      sideNav: true,
      transAppBar: true,
      whiteAppBar: false,
      menu1: false,
      userNavItems: [
        // { name: "About Us", route: "/About" },
        // { name: "Activities", route: "" },
        // { name: "Get Involved", route: "" },
        // { name: "Team", route: "/team" },
        // { name: "Donate Us", route: "/DonateUs" },
        // { name: "Contact Us", route: "/Contact" },
      ],
      activities: [
        {
          title: "Upcoming Events",
          route: "/Events",
        },
        {
          title: "Webinars",
          route: "/WebinarsHome",
        },
        {
          title: "Offline Programs",
          route: "/OfflinePrograms",
        },
      ],
      dropdown: [
        {
          title: "Publications",
        },
        {
          title: "Projects",
        },
      ],
      drop: [
        {
          title: "Volunteer",
          route: "/Volunteer",
        },
        {
          title: "Donate",
          route: "/About",
        },
        {
          title: "Membership",
          route: "/membership",
        },
      ],
      subs: [
        { name: "Publications", route: "/Publications" },
        { name: "Projects", route: "/Projects" },
      ],
      // userNavItems: [
      //   { name: "Press release", route: "/" },
      // ],
    };
  },
  computed: {
    // appType() {
    //   return this.$store.state.userType;
    // },
    menus() {
      return this.userNavItems;
    },
  },
  methods: {
    interact() {
      this.appLoading = true;
      setTimeout(() => {
        this.appLoading = false;
      }, 1000);
    },
    // scrollToElement(id) {
    //   if (this.$route.path !== "/") {
    //     this.$router.push("/").then(() => {
    //       setTimeout(function () {
    //         if (window.innerWidth >= 2500) {
    //           document.body.scrollTop = 1300;
    //           document.documentElement.scrollTop = 1320;
    //         } else {
    //           document.body.scrollTop = 390;
    //           document.documentElement.scrollTop = 400;
    //         }
    //       }, 100);
    //       document.getElementById(id).scrollIntoView({
    //         behavior: "smooth",
    //       });
    //     });
    //   } else {
    //     document.getElementById(id).scrollIntoView({
    //       behavior: "smooth",
    //     });
    //   }
    // },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;

      if (top > 2) {
        this.transAppBar = false;

        // console.log("scrolled", top)
      } else {
        this.transAppBar = true;
      }
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.headGrad {
  background: transparent;

  /* linear-gradient(104deg, #000000bb 0%, #7e7e7e2a 100%, transparent 100%); */
}
.hov {
  color: #000 !important;
}
.hid {
  color: #000 !important;
}
.hov:hover {
  color: #005f32 !important;
  border-top: 2px solid #005f32;
  border-bottom: 2px solid #005f32;
  padding: 8px;
}
.hid:hover {
  color: #005f32 !important;
  border-top: 2px solid #005f32;
  border-bottom: 2px solid #005f32;
  padding: 8px;
}
.hovbtn {
  color: #ffffff !important;
}
.hovbtn:hover {
  color: #f07305 !important;
}
</style>
