<template>
  <div id="appNavbar">
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-0
          text-none
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <!-- <router-link :to="item.route"> -->
              <v-icon>{{ item.icon }}</v-icon>

              <a :href="item.route">
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'TitBold',
                        }
                      : {
                          'font-family': 'TitLight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </a>
              <!-- </router-link> -->
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#005f32"
      dense
      flat
      height="50px"
      class="hidden-md-and-up"
    >
      <!-- <v-app-bar-nav-icon @click.native="sideNav = false">
        <v-icon color="#ffffff">mdi-menu</v-icon>
      </v-app-bar-nav-icon> -->
      <!-- <v-toolbar-title> -->
        <v-layout wrap>
          <v-flex xs12>

          
        <router-link to="/">
          <v-layout wrap justify-center>
            <!-- <v-flex xs9 align-self-center class="hidden-sm-and-up">
              <span
                class="hidden-sm-and-up"
                style="
                  font-family: TitSemibold;
                  font-size: 16px;
                  cursor: pointer;
                  color: #ffffff;
                "
              >
                WTI
              </span>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 16px;
                  cursor: pointer;
                  color: #FFFFFF;
                "
                class="hidden-xs-only"
              >
              </span>
            </v-flex> -->

            <v-flex xs3 pa-0 sm3 md3 lg2 xl1 align-self-center text-left>
              <router-link class="routerlink" :to="(name = '/')">
                <v-img
                  contain
                  height="50px"
                  :src="require('./../assets/images/wtilogo.png')"
                ></v-img>
              </router-link>
            </v-flex>
            <v-flex align-self-center text-right v-if="$route.name == 'home'">
              <div>
                <v-btn
                  color="#f07305"
                  small
                  outlined
                  text
                  :to="'/Winners'"
                  class="rounded-xl text-none"
                  @click="interact()"
                >
                  <span
                    class="hovbtn"
                    style="
                  font-family: poppinssemibold; 
                  cursor: pointer; 
                "
                  >
                    View Winners <v-icon>mdi-arrow-right</v-icon>
                  </span>
                </v-btn>
              </div>
            </v-flex>
            <v-flex align-self-center text-right v-else>
              <div>
                <v-btn
                  color="#f07305"
                  small
                  outlined
                  text
                  :to="'/'"
                  class="rounded-xl text-none"
                  @click="interact()"
                >
                  <span
                    class="hovbtn"
                    style="
                  font-family: poppinssemibold; 
                  cursor: pointer;
                  
                "
                  >
                    Go Home <v-icon>mdi-arrow-left</v-icon>
                  </span>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </router-link>
        </v-flex>
        </v-layout>
      <!-- </v-toolbar-title> -->
    </v-app-bar>
  </div>
</template>
<script>
// import AccountMenu from "./../components/accountMenu";
export default {
  components: {
    // AccountMenu,
  },
  data() {
    return {
      appLoading: false,
      sideNav: false,
      // navItems: [

      //   { name: "Rules", route: "/" },
      //   { name: "Resources", route: "/" },
      //   { name: "Project", route: "/" },
      //   { name: "Updates", route: "/" },
      //   { name: "Discussion", route: "/" },
      //   { name: "FAQ", route: "/" },
      // ],
      navItems: [
        // { name: "About Us", route: "/About" },
        // // { name: "Research", route: "" },
        // { name: "Activities", route: "" },
        // { name: "Get Involved", route: "" },
        // { name: "Team", route: "/team" },
        // { name: "Donate Us", route: "/DonateUs" },
        // { name: "Contact Us", route: "/Contact" },
      ],
      activities: [
        {
          title: "Upcoming Events",
          route: "/Events",
        },

        {
          title: "Webinars",
          route: "/WebinarsHome",
        },
        {
          title: "Offline Programs",
          route: "/OfflinePrograms",
        },
      ],
      dropdown: [
        {
          title: "Publications",
        },
        {
          title: "Projects",
        },
      ],
      drop: [
        {
          title: "Volunteer",
          route: "/Volunteer",
        },
        {
          title: "Donate",
          route: "/About",
        },
        {
          title: "Membership",
          route: "/membership",
        },
      ],
    };
  },
  computed: {
    appNavItems() {
      return this.navItems;
    },
  },
  methods: {
    interact() {
      this.appLoading = true;
      setTimeout(() => {
        this.appLoading = false;
      }, 1000);
    },
  },
};
</script>

<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: TitR !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}

.hovbtn {
  color: #ffffff !important;
}
.hovbtn:hover {
  color: #f07305 !important;
}
</style>
