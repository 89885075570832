import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
let router = new Router({
    mode: "history",
    routes: [
         
        {
            path: "/",
            name: "home",
            props: true,
            component: () =>
                import ("./views/Home/indexx"),

        },
        {
            path: "/Winners",
            name: "Winners",
            props: true,
            component: () =>
                import ("./views/Winners/indexx"),

        },

       
         

        {
            path: "/servererror",
            name: "servererror",
            props: true,
            component: () =>
                import ("./layout/500"),
            meta: {
                requiresAuth: false,
            },
        },
        {
            path: "*",
            name: "404pagenotfound",
            props: true,
            component: () =>
                import ("./layout/404"),
            meta: {
                requiresAuth: false,
            },
        },
    ],
    scrollBehavior() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    },
});
// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((route) => route.meta.requiresAuth == true) &&
//     store.state.isLoggedIn == false
//   ) {
//     console.log(from);
//     next({ name: "login", params: { lastPage: from } });
//     return;
//   }
//   if (store.state.isLoggedIn == true) {
//     axios({
//       method: "POST",
//       url: "/validate/token",
//       headers: {
//         token: localStorage.getItem("token"),
//       },
//     })
//       .then((response) => {
//         if (!response.data.status) {
//           store.commit("sessionOut", true);
//           return;
//         }
//       })
//       .catch((err) => {
//         var msg = err;
//         console.log(msg);
//       });
//   }
//   if (
//     (to.name == "login" || to.name == "home") &&
//     store.state.isLoggedIn == true
//   ) {
//     next({ name: "dashboard" });
//     return;
//   }
//   next();
// });

export default router;